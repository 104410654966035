import React from "react";
import "./welcomePart.css";
import desktopWelcomeBrush from "../../../images/desktopWelcomeBrush.png";
import desktopWelcomeWhiteBrush from "../../../images/desktopWelcomeWhiteBrush.png";
import desktopWelcomeFlag from "../../../images/desktopWelcomeFlag.png";
import desktopWelcomeRestrict from "../../../images/desktopWelcomeRestrict.png";
import desktopWelcomeLogo from "../../../images/desktopWelcomeLogo.png";
import desktopWelcomeCan from "../../../images/desktopWelcomeCan.png";
import desktopWelcomeIcons from "../../../images/desktopWelcomeIcons.png";
import newDesktopWelcomeIcons from "../../../images/newDesktopWelcomeIcons.png";
import mobileWelcomeBrush from "../../../images/mobileWelcomeBrush.png";
import { Link } from "react-scroll";

const WelcomePart = (props) => (
  <div className="welcome">
    <div className="container">
      <div className="text">

        <div className="banner2024">
          <div className="title">
            STVARAMO UMETNOST <br className="disableDesktop" /> INSPIRISANI ITALIJOM
          </div>

          <div className="subtitle">
            Glasanje traje od 13.05. do 14.07.2024. godine, a student sa
            najboljim radom će osvojiti sjajnu nagradu
          </div>

          {/*<div className="whiteBrushContainer">
            <img
              className="desktopWelcomeWhiteBrush disableDesktop"
              src={desktopWelcomeWhiteBrush}
            />
            <div className="date">
              Niš <br />
              TC Delta Planet <br />
              11.06-16.06.
            </div>
</div>*/}
        </div>



        {/*<div className="info disableDesktop">
        Glasanje traje od 13.05. do 14.07.2024. godine, a student sa
         najboljim radom će osvojiti sjajnu nagradu
        </div>
        <div className="mobileInfo">
        Glasanje će trajati od 13.05. do 14.07.2024. godine, a student sa 
        najboljim radom osvaja sjajnu nagradu
        </div>

        <div className="whiteBrushContainer">
          <img
            className="desktopWelcomeWhiteBrush disableDesktop"
            src={desktopWelcomeWhiteBrush}
          />
          <div className="date">
            Beograd <br />
            TC Galerija <br />
            05.06 – 11.06.
          </div>

          <div className="date">
            Novi Sad <br />
            TC Promenada <br />
            13.06 – 18.06.
          </div>

          <div className="date">
            Niš <br />
            TC Delta Planet <br />
            20.06 – 25.06.
          </div>
</div> */}
      </div>

      <img className="mobileWelcomeBrush" src={mobileWelcomeBrush} />
      <img className="desktopWelcomeBrush" src={desktopWelcomeBrush} />
      <img className="desktopWelcomeFlag" src={desktopWelcomeFlag} />
      <img className="desktopWelcomeRestrict" src={desktopWelcomeRestrict} />
      <img className="desktopWelcomeLogo" src={desktopWelcomeLogo} />
      <img className="desktopWelcomeCan" src={desktopWelcomeCan} />
      <img className="desktopWelcomeIcons" src={newDesktopWelcomeIcons} />
    </div>

    <div className="btns">
      <div
        className="btn"
        onClick={() =>
          document
            .getElementById("section3")
            .scrollIntoView({ behavior: "smooth" })
        }
      >
        {" "}
        UPOZNAJ UMETNIKE{" "}
      </div>
      <div
        className="btn"
        onClick={() =>
          document
            .getElementById("section4")
            .scrollIntoView({ behavior: "smooth" })
        }
      >
        {" "}
        GLASAJ{" "}
      </div>
    </div>
  </div>
);

export default WelcomePart;
