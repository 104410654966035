import React, { useEffect } from "react";
import "./mainPopup.css";
import closeIconRed from "../../images/closeIconRed.png";

const MainPopup = (props) => {
  useEffect(() => {
    document
      .getElementById("header")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  const successMsg =
    "Molimo Vas da proverite vašu e-mail adresu i verifikujete svoj glas.";

  return (
    <div className="mainPopup">
      <div className="content">
        {props.approveMessage
          ? props.approveMessage.toUpperCase()
          : successMsg.toUpperCase()}

        <div
          className="close"
          onClick={() =>
          (window.parent.location.href =
            "https://birramoretti.com/rs/sr/umetnost")
          }
        >
          <img src={closeIconRed} />
        </div>
      </div>
    </div>
  );
};

export default MainPopup;
